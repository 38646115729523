import "../global/globalOverrides";
import "../scss/backToTopButton.scss";
import "../ts/listing";
import { ClickStream } from "../ts/clickstream";
import { BackToTopButton } from "../ts/common/backToTopButton";
import { GTMLoader } from "../ts/gtmLoader";
import { CardContainer } from "../ts/cardContainer";
import { InfinityLoader } from "../ts/infinityLoader";
$(function () {
    new GTMLoader("GTM-NLVDLKV", window);
    new InfinityLoader(window);
    new InfinityLoader(window);
    new ClickStream().bootstrap();
    new BackToTopButton(window, $(".back-to-top", $(document)));
    new CardContainer($(".product-list"));
});
